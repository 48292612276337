import React, { Component } from 'react';
import WidgetCard from '../../components/widgetCard/WidgetCard';
import './history.scss';
import OrderTableHistory from '../../components/orderTable/OrderTableHistory';
import * as Titles from '../../util/Titles';
import axios from "axios";

export class History extends Component {
  constructor(props) {
    super(props);
    this.state = { storeCode: this.props.storeId };
  }

  componentDidMount() {
    this.populateOrderHistoryData(this.props.storeId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeId !== this.props.storeId) {
      this.populateOrderHistoryData(this.props.storeId);
    }
  }

  async populateOrderHistoryData(storeCode) {
    this.setState({ loading: true });
    axios.get(`${process.env.REACT_APP_SERVER_URL}cardvalue/GetOrdersHistoryByStoreCode?storeCode=${storeCode}`)
    .then((response) => response.data)
    .then((data) => {
      this.setState({
        activeType: Titles.CASE_TOTAL,
        cardvalues: data,
        loading: false,
        initialOrders: data[0]?.orders,
        orders: []
      });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  handleWidgetClick(tableOrders, type) {
    this.setState({ activeType: type, initialOrders: tableOrders });
  }

  renderWidgetCards(cardValues) {
    return (
      <>
        {cardValues !== undefined ? (
          cardValues.map((cardValue, i) => (
            <WidgetCard
              key={i}
              active={this.state.activeType === cardValue.type ? true : false}
              type={cardValue.type}
              value={cardValue.statusLength}
              handler={() => this.handleWidgetClick(cardValue.orders, cardValue.type)}
            />
          ))
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    let widgetCards = this.state.loading ? (
      <p>
        <em>{Titles.LOADING}</em>
      </p>
    ) : (
      this.renderWidgetCards(this.state.cardvalues)
    );
    return (
      <div>
        <div className="widget">{widgetCards}</div>
        <div className="listContainer">
          {this.state.initialOrders === undefined || this.state.loading ? (
            <p>
              <em>{Titles.LOADING}</em>
            </p>
          ) : (
            <OrderTableHistory ordertables={this.state.initialOrders} />
          )}
        </div>
      </div>
    );
  }
}

export default History;
