export const ORDER_TABLE_HEADER = 'Orders';
export const HISTORY_TABLE_HEADER = 'History';
export const ACTION_BOX_MODAL_HEADER = 'Action details';
export const STOREDATA_CACHE_NAME = 'StoreData';
export const STOREDATA_CACHE_STOREID = 'storeId';
export const STOREDATA_CACHE_AREAID = 'areaId';
export const STOREDATA_CACHE_AREA = 'area';
export const ALLSTORES = 'AllStores';
export const ALLAREAS = 'AllAreas';
export const CASE_TOTAL = 'total';
export const CASE_RECEIVED = 'received';
export const CASE_PICK = 'pick';
export const CASE_PACK = 'pack';
export const CASE_READY = 'ready';
export const CASE_READY_TO_COLLECT = 'readyToCollect';
export const CASE_LATER_ORDERS = 'laterOrders';
export const CASE_SHORT_PICK = 'shortPick';
export const CASE_CANCELLED = 'cancelled';
export const CASE_UNCOLLECTED = 'uncollected';
export const CASE_COMPLETED = 'completed';
export const CASE_NEW_ORDERS = 'newOrders';
export const LOADING = 'Loading...';
export const MONTH = 'month';
export const YESTERDAY = 'yesterday';
export const TWO_WEEKS = 'twoWeeks';
export const WEEK = 'week';
export const ACTIVE = 'active';
export const REALTIME_DATE_NAME = 'Real time data';
export const ACTIONBOX_NAME = 'Actions';
export const HISTORY_CARDS_NAME = 'History data';
export const SIDEBAR_MENU_OVERVIEW = 'Overview';
export const SIDEBAR_MENU_DASHBOARD = 'Dashboard';
export const SIDEBAR_MENU_HISTORY = 'History';
export const SIDEBAR_MENU_ANALYTICS = 'Analytics';
export const PRODUCT_DESCRIPTION_ARTNR = 'ArtNr:';
export const PRODUCT_DESCRIPTION_DESCRIPTION = 'Description:';
export const PRODUCT_DESCRIPTION_COLOR = 'Color:';
export const PRODUCT_DESCRIPTION_QUANTITY = 'Quantity:';
export const PRODUCT_DESCRIPTION_SIZE = 'Size:';
export const SEARCH_INPUT_HELP_TEXT = 'type order number..';
export const DATE_FILTER_BUTTON_TODAY = 'Today';
export const DATE_FILTER_BUTTON_2DAYS = 'Last 2 Days';
export const DATE_FILTER_BUTTON_7DAYS = 'Last 7 Days';
export const ORDER_TABLE_HEADER_TOOLTIP = 'Displayed for the last 30 days';
export const HOURS_LEFT_OVERDUE = 'Overdue';
export const HOURS_LEFT_SHORT = 'Short';
export const HOURS_LEFT_LONG = 'Long';
export const ORDER_STATUS_SHORT_PICK = 'ShortPick';
export const ORDER_STATUS_LATE = 'Late';
export const ORDER_STATUS_PENDING = 'Pending';
export const ORDER_STATUS_UNCOLLECTED = 'Uncollected';
export const CELL_NAME_ORDER_NUMBER = 'Order Number';
export const CELL_NAME_FINISH_DATE = 'Finish Date';
export const CELL_NAME_ORDER_QUANTITY = 'Order Quantity';
export const CELL_NAME_STATUS = 'Status';
export const CELL_NAME_STORE = 'Store';
export const CELL_NAME_TIME_LEFT = 'Time Left';
export const CELL_NAME_DUE_DATE = 'Due Date';
export const CELL_NAME_CREATED_DATE = 'Created Date';
export const SEARCH_HEADER_NAME = 'Order Search';
export const ORDER_DETAIL_HEADER_NAME = 'Order detail';
export const ORDER_DETAIL_NUMBER = 'Number: ';
export const ORDER_DETAIL_DUEDATE = 'Due date: ';
export const ORDER_DETAIL_STATUS = 'Status: ';
export const HEADER_NAME = 'Fulfill From Store';
export const TOOLTIP_TEXT_MONTH = 'All orders for last 30 days';
export const TOOLTIP_TEXT_YESTERDAY = 'All orders for previous day';
export const TOOLTIP_TEXT_WEEK = 'All orders for week before current week';
export const TOOLTIP_TEXT_TWOWEEKS = 'All orders for two previous weeks';
export const ACTION_BOX_TOTAL_NAME = 'Total actions:';
export const ACTION_BOX_LATE_NAME = 'Late orders:';
export const ACTION_BOX_SCAN_NAME = 'Orders pending action:';
export const ACTION_BOX_UNCOLLECT_NAME = 'Orders to uncollect:';
export const DROPDOWN_ALLAREAS = 'All areas';
export const DROPDOWN_ALLSTORES = 'All stores';
