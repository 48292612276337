import './order-table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import React, { Component } from 'react';
import OrderRow from '../fragments/OrderRow';
import OrderTableRowHistoryData from './orderTableRow/OrderTableRowHistoryData';
import OrderTableHeaderPanel from '../orderTableHeaderPanel/OrderTableHeaderPanel';
import { HistoryDataHeader } from './orderTableHeader/HistoryDataHeader';
import * as Titles from '../../util/Titles';

const oneDayInterval = 86400000;
const twoDaysInterval = 172800000;
const oneWeekInterval = 604800000;

class OrderTableHistory extends Component {
  previouslyClickedHeader = '0';
  filtered = false;
  headerFilterIsActive = false;

  constructor(props) {
    super(props);
    this.onHeaderClick = this.onHeaderClick.bind(this);
    this.state = { orders: this.props.ordertables, loading: true, cardIsOpen: false, orderIds: [] };
  }

  onAutocompleteChange = (event) => {
    this.headerFilterIsActive = true;
    if (event.target != null) {
      if (event.target.value !== '') {
        let filteredOrders = this.props.ordertables.filter((el) =>
          el.referenceNumber.includes(event.target.value)
        );
        this.setState({ orders: filteredOrders });
      } else {
        this.setState({ orders: this.props.ordertables });
      }
    }
  };

  onAutocompleteFocus = () => {
    let orderIds = this.props.ordertables.map((i) => {
      return i.referenceNumber;
    });
    this.setState({ orderIds: orderIds });
  };

  mapOrderIds() {
    return this.props.ordertables?.map((item) => {
      return { id: item.referenceNumber, label: item.referenceNumber };
    });
  }

  onHeaderClick = (e, headerValue) => {
    let filteredOrders = [];
    this.headerFilterIsActive = true;
    if (this.previouslyClickedHeader === headerValue) {
      if (this.filtered) {
        this.headerFilterIsActive = false;
      }
      this.filtered = !this.filtered;
    } else {
      this.filtered = true;
    }
    this.previouslyClickedHeader = headerValue;
    switch (headerValue) {
      case '1':
        filteredOrders = this.props.ordertables.filter((el) => {
          return this.dateIsWithinTheInterval(
            Date.parse(el.createdDate),
            Date.now(),
            oneDayInterval
          );
        });
        this.setState({ orders: filteredOrders });
        break;
      case '2':
        filteredOrders = this.props.ordertables.filter((el) => {
          return this.dateIsWithinTheInterval(
            Date.parse(el.createdDate),
            Date.now(),
            twoDaysInterval
          );
        });
        this.setState({ orders: filteredOrders });
        break;
      case '3':
        filteredOrders = this.props.ordertables.filter((el) => {
          return this.dateIsWithinTheInterval(
            Date.parse(el.createdDate),
            Date.now(),
            oneWeekInterval
          );
        });
        this.setState({ orders: filteredOrders });
        break;
      default:
        this.setState({ orders: [] });
        break;
    }
  };

  dateIsWithinTheInterval = (endDate, startDate, comparer) => {
    return startDate - endDate < comparer;
  };

  renderTableRows(orders) {
    {
      return orders.map((order) => {
        return (
          <OrderRow
            order={order}
            key={order.referenceNumber}
            dataRow={<OrderTableRowHistoryData order={order} />}
          />
        );
      });
    }
  }

  renderOverViewTable() {
    let orders = this.headerFilterIsActive ? this.state.orders : this.props.ordertables;
    this.headerFilterIsActive = false;
    let orderIds = this.mapOrderIds();
    return (
      <TableContainer component={Paper} className="table">
        <OrderTableHeaderPanel
          tableHeader={Titles.HISTORY_TABLE_HEADER}
          orderIds={orderIds}
          onHeaderClick={this.onHeaderClick}
          onAutocompleteChange={this.onAutocompleteChange}
        />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <HistoryDataHeader />
          </TableHead>
          <TableBody>{this.renderTableRows(orders)}</TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    let contents = this.renderOverViewTable();
    return <div>{contents}</div>;
  }
}

export default OrderTableHistory;
