import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
      clientId: `${process.env.REACT_APP_CLIENT_ID}`,
      authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
      redirectUri: `${process.env.REACT_APP_CALLBACK_URL}`,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
            }
          },
        },
      },
    };
  
// Can be found in the API Permissions of the ASP.NET Web API
export const stagingApiScope = {
  scopes: ["api://a129f384-05e5-4d03-b0ea-2529200d8292/api.scope"]
};

export const productionApiScope = {
  scopes: ["api://3c4f8a93-f02d-467f-aca4-8b23f1277342/api.scope"]
};