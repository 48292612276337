import { TableCell } from '@mui/material';
import '../action-box.scss';
import * as Titles from '../../../util/Titles';

export const TableDataRowLateOrder = (props) => {
  let timeLeft =
    props.order.hoursLeft < 1
      ? Titles.HOURS_LEFT_OVERDUE
      : props.order.hoursLeft < 6
      ? Titles.HOURS_LEFT_SHORT
      : Titles.HOURS_LEFT_LONG;

  return (
    <>
      <TableCell className="tableCell">{props.order.referenceNumber}</TableCell>
      <TableCell className="tableCell">{props.order.siteId}</TableCell>
      <TableCell className="tableCell">
        <span className={props.order.hoursLeftToDisplay === '' ? '' : `timeLeft ${timeLeft}`}>
          {props.order.hoursLeftToDisplay}
        </span>
      </TableCell>
      <TableCell className="tableCell">{props.order.sumQuantity}</TableCell>
      <TableCell className="tableCell">
        <span className={`status ${props.order.status}`}>{props.order.displayStatus}</span>
      </TableCell>
    </>
  );
};

export default TableDataRowLateOrder;
