import { TableRow, TableCell } from '@mui/material';
import * as Titles from '../../../util/Titles';

const DataHeader = () => {
  return (
    <TableRow>
      <TableCell className="tableCella"></TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_ORDER_NUMBER}</TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_CREATED_DATE}</TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_DUE_DATE}</TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_ORDER_QUANTITY} </TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_TIME_LEFT}</TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_STATUS}</TableCell>
    </TableRow>
  );
};

export default DataHeader;
