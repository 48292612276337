import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import * as Titles from '../../../util/Titles';

export const TableHeaderPendingOrder = () => {
  return (
    <TableHead>
      <TableCell className="tableCella"></TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_ORDER_NUMBER}</TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_STORE}</TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_ORDER_QUANTITY}</TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_STATUS}</TableCell>
    </TableHead>
  );
};

export default TableHeaderPendingOrder;
