export function AddDataToCache(cacheName, id, data) {
  const response = new Response(JSON.stringify(data));
  if ('caches' in window) {
    // Opening given cache and putting our data into it
    caches.open(cacheName).then((cache) => {
      cache.put(id, response);
    });
  }
}

export async function GetMultipleCacheData(cacheNames) {
  if (typeof caches === 'undefined') return false;

  var cacheDataArray = [];
  for (var i = 0; i < cacheNames.length; i++) {
    const cacheStorage = await caches.open(cacheNames[i].cacheName);
    const cachedResponse = await cacheStorage.match(cacheNames[i].id);

    // If no cache exists
    if (!cachedResponse || !cachedResponse.ok) {
      cacheDataArray[i] = `Unable to fetch ${cacheNames[i].cacheName}`;
    } else {
      var data = await cachedResponse.json();
      cacheDataArray[i] = data;
      //this.state[cacheNames[i].id] = data;
    }
  }
  return cacheDataArray;
}
