import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import '../action-box.scss';
import OrderRow from '../../fragments/OrderRow';
import TableDataRowLateOrder from '../dataTable/TableDataRowLateOrder';
import TableDataRowUncollectedOrder from '../dataTable/TableDataRowUncollectedOrder';
import TableDataRowPendingOrder from '../dataTable/TableDataRowPendingOrder';
import TableHeaderLateOrder from '../dataTable/TableHeaderLateOrder';
import TableHeaderUncollectedOrder from '../dataTable/TableHeaderUncollectedOrder';
import TableHeaderPendingOrder from '../dataTable/TableHeaderPendingOrder';
import * as Titles from '../../../util/Titles';

export const Modal = (props) => {
  const renderTableRows = () => {
    switch (props.type) {
      case Titles.ORDER_STATUS_LATE:
        return props.data.map((order) => {
          return (
            <OrderRow
              key={order.id}
              order={order}
              dataRow={<TableDataRowLateOrder order={order} />}
            />
          );
        });
      case Titles.ORDER_STATUS_PENDING:
        return props.data.map((order) => {
          return (
            <OrderRow
              key={order.id}
              order={order}
              dataRow={<TableDataRowPendingOrder order={order} />}
            />
          );
        });
      case Titles.ORDER_STATUS_UNCOLLECTED:
        return props.data.map((order) => {
          return (
            <OrderRow
              key={order.id}
              order={order}
              dataRow={<TableDataRowUncollectedOrder order={order} />}
            />
          );
        });
      default:
        return <></>;
    }
  };

  const renderTableHeader = () => {
    switch (props.type) {
      case Titles.ORDER_STATUS_LATE:
        return <TableHeaderLateOrder />;
      case Titles.ORDER_STATUS_UNCOLLECTED:
        return <TableHeaderUncollectedOrder />;
      case Titles.ORDER_STATUS_PENDING:
        return <TableHeaderPendingOrder />;
      default:
        return <></>;
    }
  };

  return (
    <div className="modal">
      <div className="popup scrollable-div">
        <div className="modal-header">
          <h4 className="modal-header-title">{Titles.ACTION_BOX_MODAL_HEADER}</h4>
          <div className="close-btn" onClick={props.onClose}>
            {' '}
            <CloseIcon />{' '}
          </div>
        </div>
        <div className="modal-info">
          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              {renderTableHeader()}
              <TableBody>{renderTableRows()}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default <Modal />;
