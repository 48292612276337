import { TableCell, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';

export const RowExpandingArrowCell = (props) => {
  const [open, setOpen] = useState(props.open);
  return (
    <TableCell>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => {
          props.onRowExpand(!open);
          setOpen(!open);
        }}>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </TableCell>
  );
};

export default RowExpandingArrowCell;
