import React, { Component } from 'react';
import './action-box.scss';
import { Modal } from './modal/Modal';
import * as Titles from '../../util/Titles';

class ActionBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsData: this.props.data,
      show: false,
      loading: this.props.loading,
      modalData: [],
      type: ''
    };
  }

  showActionModel = (modalData, type) => {
    this.setState({ modalData: modalData, show: !this.state.show, type: type });
  };

  render() {
    return (
      <>
        {this.state.show && (
          <Modal
            onClose={this.showActionModel}
            data={this.state.modalData}
            type={this.state.type}
          />
        )}
        <div className="action-box">
          <div>
            {Titles.ACTION_BOX_TOTAL_NAME} &nbsp;
            {this.props.loading ? (
              <span className="loader"></span>
            ) : (
              <span>{this.props.data.totalActions}</span>
            )}
          </div>
          <div>
            {Titles.ACTION_BOX_LATE_NAME} &nbsp;
            {this.props.loading ? (
              <span className="loader"></span>
            ) : (
              <span
                className="pointer"
                onClick={() => this.showActionModel(this.props.data?.lateOrders, 'Late')}>
                {this.props.data?.lateOrders?.length}
              </span>
            )}
          </div>
          <div>
            {Titles.ACTION_BOX_SCAN_NAME} &nbsp;
            {this.props.loading ? (
              <span className="loader"></span>
            ) : (
              <span
                className="pointer"
                onClick={() =>
                  this.showActionModel(this.props.data?.pendingOrders, 'Pending')
                }>
                {this.props.data?.pendingOrders?.length}
              </span>
            )}
          </div>
          <div>
            {Titles.ACTION_BOX_UNCOLLECT_NAME} &nbsp;
            {this.props.loading ? (
              <span className="loader"></span>
            ) : (
              <span
                className="pointer"
                onClick={() =>
                  this.showActionModel(this.props.data?.uncollectedOrders, 'Uncollected')
                }>
                {this.props.data?.uncollectedOrders?.length}
              </span>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ActionBox;
