import React from 'react';
import './widget-card.scss';
import * as Titles from '../../util/Titles';

class WidgetCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {}, props: this.props };
    this.dataTest = {};
  }

  componentDidMount() {
    this.fillData(this.state.props);
  }

  fillData(properties) {
    switch (properties.type) {
      case Titles.CASE_TOTAL:
        this.dataTest = {
          title: 'Total',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_RECEIVED:
        this.dataTest = {
          title: 'Received',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      /*  case Titles.CASE_DUETODAY:
        this.dataTest = {
          title: 'Due Today',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;  */
      case Titles.CASE_PICK:
        this.dataTest = {
          title: 'Pick',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_PACK:
        this.dataTest = {
          title: 'Pack',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_READY:
        this.dataTest = {
          title: 'Ready',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_READY_TO_COLLECT:
        this.dataTest = {
          title: 'Ready to collect',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_LATER_ORDERS:
        this.dataTest = {
          title: 'Late Orders',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_SHORT_PICK:
        this.dataTest = {
          title: 'Short pick',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_CANCELLED:
        this.dataTest = {
          title: 'Cancelled',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_UNCOLLECTED:
        this.dataTest = {
          title: 'Uncollected',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_COMPLETED:
        this.dataTest = {
          title: 'Completed',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      case Titles.CASE_NEW_ORDERS:
        this.dataTest = {
          title: 'New orders',
          amount: properties.value,
          percentage: properties.percentage
        };
        break;
      default:
        this.dataTest = { title: '', amount: '', percentage: '' };
        break;
    }
    return (
      <div
        className={`widget-card 
            ${
              this.dataTest.percentage != undefined && this.dataTest.percentage != null
                ? 'extendendHeight'
                : ''
            }`}
        style={{ cursor: 'pointer', backgroundColor: this.props.active ? 'rgb(227,228,219)' : '' }}
        onClick={() => this.props.handler()}>
        <div className="title">{this.dataTest.title}</div>
        <div className="amount">{this.dataTest.amount}</div>
        <div className="percentage">{this.dataTest.percentage}</div>
      </div>
    );
  }

  render() {
    let content = this.fillData(this.props);
    return <>{content}</>;
  }
}

export default WidgetCard;
