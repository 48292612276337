import './order-table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DehazeIcon from '@mui/icons-material/Dehaze';
import React, { Component } from 'react';
import OrderDetails from '../orderDetails/OrderDetails';
import OrderTableHeaderPanel from '../orderTableHeaderPanel/OrderTableHeaderPanel';
import { FormatDateTime } from '../../util/Util';
import DataHeader from './orderTableHeader/DataHeader';
import * as Titles from '../../util/Titles';

const oneDayInterval = 86400000;
const twoDaysInterval = 172800000;
const oneWeekInterval = 604800000;
let headerFilterIsActive = false;

class OrderTable extends Component {
  previouslyClickedHeader = '0';
  filtered = false;
  clickedOrderDetails = {};

  constructor(props) {
    super(props);
    this.onHeaderClick = this.onHeaderClick.bind(this);
    this.state = {
      ordertables: this.props.ordertables,
      loading: true,
      cardIsOpen: false,
      orderIds: []
    };
  }

  setCardIsOpen = (productValue) => {
    this.setState({ cardIsOpen: !this.state.cardIsOpen });
    this.clickedOrderDetails = productValue;
  };

  onAutocompleteChange = (event) => {
    headerFilterIsActive = true;
    if (event.target != null) {
      if (event.target.value !== '') {
        let filteredOrders = this.props.ordertables.filter((el) =>
          el.referenceNumber.includes(event.target.value)
        );
        this.setState({ ordertables: filteredOrders });
      } else {
        this.setState({ ordertables: this.props.ordertables });
      }
    }
  };

  onAutocompleteFocus = () => {
    let orderIds = this.props.ordertables.map((i) => {
      return i.referenceNumber;
    });
    this.setState({ orderIds: orderIds });
  };

  mapOrderIds() {
    return this.props.ordertables?.map((item) => {
      return { id: item.referenceNumber, label: item.referenceNumber };
    });
  }

  onHeaderClick = (e, headerValue) => {
    let filteredOrders = [];
    headerFilterIsActive = true;
    if (this.previouslyClickedHeader === headerValue) {
      if (this.filtered) {
        headerFilterIsActive = false;
      }
      this.filtered = !this.filtered;
    } else {
      this.filtered = true;
    }
    this.previouslyClickedHeader = headerValue;
    switch (headerValue) {
      case '1':
        filteredOrders = this.props.ordertables.filter((el) => {
          return this.dateIsWithinTheInterval(
            Date.parse(el.createdDate),
            Date.now(),
            oneDayInterval
          );
        });
        this.setState({ ordertables: filteredOrders });
        break;
      case '2':
        filteredOrders = this.props.ordertables.filter((el) => {
          return this.dateIsWithinTheInterval(
            Date.parse(el.createdDate),
            Date.now(),
            twoDaysInterval
          );
        });
        this.setState({ ordertables: filteredOrders });
        break;
      case '3':
        filteredOrders = this.props.ordertables.filter((el) => {
          return this.dateIsWithinTheInterval(
            Date.parse(el.createdDate),
            Date.now(),
            oneWeekInterval
          );
        });
        this.setState({ ordertables: filteredOrders });
        break;
      default:
        this.setState({ ordertables: [] });
        break;
    }
  };

  dateIsWithinTheInterval = (endDate, startDate, comparer) => {
    return startDate - endDate < comparer;
  };

  renderOverViewTable(ordertables) {
    headerFilterIsActive = false;
    let orderIds = this.mapOrderIds();
    return (
      <TableContainer component={Paper} className="table">
        <OrderTableHeaderPanel
          tableHeader={Titles.ORDER_TABLE_HEADER}
          orderIds={orderIds}
          onHeaderClick={this.onHeaderClick}
          onAutocompleteChange={this.onAutocompleteChange}
        />
        {this.state.cardIsOpen && (
          <OrderDetails
            onClose={this.setCardIsOpen}
            orderDetailData={this.clickedOrderDetails}
            storeCode={this.props.storeCode}
          />
        )}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <DataHeader />
          </TableHead>
          <TableBody>
            {ordertables === undefined ? (
              <TableRow></TableRow>
            ) : (
              ordertables.map((ordertable) => {
                let timeLeft =
                  ordertable.hoursLeft < 1
                    ? Titles.HOURS_LEFT_OVERDUE
                    : ordertable.hoursLeft < 6
                    ? Titles.HOURS_LEFT_SHORT
                    : Titles.HOURS_LEFT_LONG;
                return (
                  <TableRow key={ordertable.referenceNumber}>
                    <TableCell className="tableCell">
                      <DehazeIcon
                        className="OpenModal"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setCardIsOpen(ordertable)}
                      />
                    </TableCell>
                    <TableCell className="tableCell">{ordertable.referenceNumber}</TableCell>
                    <TableCell className="tableCell">
                      {FormatDateTime(ordertable.createdDate)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {FormatDateTime(ordertable.dueDate)}
                    </TableCell>
                    <TableCell className="tableCell">{ordertable.sumQuantity}</TableCell>
                    <TableCell className="tableCell">
                      <span
                        className={
                          ordertable.hoursLeftToDisplay === '' ? '' : `timeLeft ${timeLeft}`
                        }>
                        {ordertable.hoursLeftToDisplay}
                      </span>
                    </TableCell>
                    <TableCell className="tableCell">
                      <span className={`status ${ordertable.displayStatus}`}>
                        {ordertable.displayStatus}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    let contents = headerFilterIsActive
      ? this.renderOverViewTable(this.state.ordertables)
      : this.renderOverViewTable(this.props.ordertables);
    return <div>{contents}</div>;
  }
}

export default OrderTable;
