import './order-details.scss';
import CloseIcon from '@mui/icons-material/Close';
import React, { Component } from 'react';
import ProductDescription from '../productDescription/ProductDescription';
import { FormatDateTime } from '../../util/Util';
import * as Titles from '../../util/Titles';
import axios from "axios";

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { productDetails: [], loading: true };
    this.fetchProductDetails(this.props.storeCode);
  }

  async fetchProductDetails(storeCode) {
    var skuNumbers = this.getSkus(this.props.orderDetailData);
    axios.get(`${process.env.REACT_APP_SERVER_URL}cardvalue/GetOrderDetail?${skuNumbers}&storeCode=${storeCode}`)
    .then((response) => response.data)
    .then((data) => {
      this.setOrderDetailQuantities(data);
      this.setState({ productDetails: data, loading: false });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  setOrderDetailQuantities(orderDetails) {
    orderDetails.forEach((element) => {
      element.quantity = this.props.orderDetailData.orderQuantities.filter((el) => {
        return el.sku === element.sku;
      })[0];
    });
  }

  getSkus = (orderDetailData) => {
    var skus = orderDetailData.orderQuantities
      .map((value) => {
        return 'skuNumbers=' + value.sku;
      })
      .join('&');
    return skus;
  };

  renderProductDetails(productDetails) {
    return (
      <>
        {productDetails.map((element) => (
          <ProductDescription key={element.sku} productDescription={element} />
        ))}
      </>
    );
  }

  render() {
    let productDetails = this.state.loading ? (
      <p>
        <em>{Titles.LOADING}</em>
      </p>
    ) : (
      this.renderProductDetails(this.state.productDetails)
    );

    return (
      <div className="main">
        <div className="popup scrollable-div">
          <div className="cardheader">
            <div className="cardinfo">
              <h4 className="title">{Titles.ORDER_DETAIL_HEADER_NAME}</h4>
              <p>
                {' '}
                {Titles.ORDER_DETAIL_NUMBER} {this.props.orderDetailData.referenceNumber}
              </p>
              <p>
                {' '}
                {Titles.ORDER_DETAIL_DUEDATE}{' '}
                {FormatDateTime(this.props.orderDetailData.localDueDate)}
              </p>
              <p>
                {' '}
                {Titles.ORDER_DETAIL_STATUS} {this.props.orderDetailData.status}
              </p>
            </div>
            <div className="closebtn" onClick={this.props.onClose}>
              <CloseIcon />
            </div>
          </div>
          {productDetails}
        </div>
      </div>
    );
  }
}

export default OrderDetails;
