import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
// import { TextField, IconButton } from '@material-ui/core';
// import { CloseRounded } from '@material-ui/icons';
import './order-search.scss';
import Table from '@mui/material/Table';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FormatDateTime } from '../../util/Util';
import * as Titles from '../../util/Titles';

class OrderSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchValue: '', searchResult: [] };
  }

  handleEnterClick() {
    let allOrders = this.props.orders;

    if (this.state.searchValue !== '' && allOrders.length !== 0) {
      let searchResult = allOrders.filter((i) => i.referenceNumber === this.state.searchValue);
      this.setState({ searchResult: searchResult });
    }
  }

  handleChange = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  clearInput = () => {
    this.setState({ searchValue: '' });
  };

  render() {
    return (
      <div className="listContainer">
        <div className="orderSearchHeaderContainer">
          <h3>{Titles.SEARCH_HEADER_NAME}</h3>
          <div className="textFieldBorderRadius">
            {' '}
            <TextField
              //fullWidth
              id="outlined"
              variant="outlined"
              value={this.state.searchValue}
              onChange={this.handleChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  this.handleEnterClick(event);
                }
              }}
              label="type order number.."
              size="small"
              InputProps={{
                endAdornment:
                  this.state.searchValue != '' ? (
                    <IconButton onClick={this.clearInput}>
                      {/* <CloseRounded /> */}
                    </IconButton>
                  ) : (
                    <></>
                  )
              }}
            />{' '}
          </div>
        </div>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCella">{Titles.CELL_NAME_STORE}</TableCell>
              <TableCell className="tableCella">{Titles.CELL_NAME_CREATED_DATE}</TableCell>
              <TableCell className="tableCella">{Titles.CELL_NAME_DUE_DATE}</TableCell>
              <TableCell className="tableCella">{Titles.CELL_NAME_ORDER_QUANTITY} </TableCell>
              <TableCell className="tableCella">{Titles.CELL_NAME_TIME_LEFT}</TableCell>
              <TableCell className="tableCella">{Titles.CELL_NAME_STATUS}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.searchResult.map((i) => {
              let timeLeft =
                i.hoursLeft < 1
                  ? Titles.HOURS_LEFT_OVERDUE
                  : i.hoursLeft < 6
                  ? Titles.HOURS_LEFT_SHORT
                  : Titles.HOURS_LEFT_LONG;
              return (
                <TableRow key={i.id}>
                  <TableCell>{i.siteId}</TableCell>
                  <TableCell>{FormatDateTime(i.createdDate)}</TableCell>
                  <TableCell>{FormatDateTime(i.dueDate)}</TableCell>
                  <TableCell>{i.sumQuantity}</TableCell>
                  <TableCell>
                    <span className={i.hoursLeftToDisplay === '' ? '' : `timeLeft ${timeLeft}`}>
                      {i.hoursLeftToDisplay}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={`status ${i.status}`}>{i.displayStatus}</span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default OrderSearch;
