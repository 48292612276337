import React, { Component } from 'react';
import WidgetCard from '../../components/widgetCard/WidgetCard';
import OrderSearch from '../../components/orderSearch/OrderSearch';
import AggregatedDataFilterHeader from '../../components/dataFilter/AggregatedDataFilterHeader';
import ActionBox from '../../components/actionBox/ActionBox';
import './overview.scss';
import * as Titles from '../../util/Titles';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import axios from "axios";

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=30d8f1bb-dee3-4092-8df3-6a008a91c673;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
});
appInsights.loadAppInsights();

export class Overview extends Component {
  constructor(props) {
    super(props);
    this.previouslyClickedHeader = Titles.MONTH;
    this.mounted = false;
    this.state = {
      loadingHistoryData: true,
      loadingRealData: true,
      loadingActionBox: true,
      area: this.props.area,
      searchData: []
    };
    this.onHeaderClick = this.onHeaderClick.bind(this);
  }

  componentDidMount() {
    this.populateRealTimeAggregatedData(this.props.area);
    this.populateActionBox(this.props.area);
    this.populateHistoryAggregatedData(this.props.area);
    this.populateSearchTable(this.props.area);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.area !== this.props.area) {
      this.setState({ area: this.props.area });
      this.populateRealTimeAggregatedData(this.props.area);
      this.populateActionBox(this.props.area);
      this.populateHistoryAggregatedData(this.props.area);
      this.populateSearchTable(this.props.area);
      //this.populateRealTimeAggregatedData(this.state.area);
      //this.populateHistoryAggregatedData(this.state.area);
    }
  }

  componentWillUnmount() {
    this.mounted = true;
  }

  filterData(headerValue, data) {
    let filteredData = data.map((i) => {
      i.currentValue = i.historyData[headerValue];
      i.currentPercentage = i.percentage != null ? i.percentage[headerValue] : 0;
      return i;
    });
    return filteredData;
  }

  onHeaderClick(e, headerValue) {
    if (this.previouslyClickedHeader === headerValue) {
      headerValue = Titles.MONTH;
    }
    let filteredData = this.filterData(headerValue, this.state.historyDataCards);
    if (!this.mounted) {
      this.setState({ historyDataCards: filteredData });
    }
    this.previouslyClickedHeader = headerValue;
  }

  async populateRealTimeAggregatedData(area) {
    this.setState({ loadingRealData: true });
    axios.get(`${process.env.REACT_APP_SERVER_URL}cardvalue/GetAggregatedData?area=${area}`)
    .then((response) => response.data)
    .then((data) => {
      if (!this.mounted) {
        this.setState({
          cardvaluesRealTime: data.cardValues,
          loadingRealData: false
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  async populateActionBox(area) {
    this.setState({ loadingActionBox: true });
    axios.get(`${process.env.REACT_APP_SERVER_URL}cardvalue/GetActions?area=${area}`)
    .then((response) => response.data)
    .then((data) => {
      this.setState({ actionsData: data, loadingActionBox: false });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  async populateHistoryAggregatedData(area) {
    this.setState({ loadingHistoryData: true });
    appInsights.trackEvent({ name: 'GetAggregatedHistoryData', properties: { "test": area } });

    axios.get(`${process.env.REACT_APP_SERVER_URL}cardvalue/GetAggregatedHistoryData?area=${area}`)
    .then((response) => response.data)
    .then((data) => {
      console.log("data ----------------- " + data);
      let filteredData = this.filterData(this.previouslyClickedHeader, data);
      if (!this.mounted) {
        this.setState({ historyDataCards: filteredData, loadingHistoryData: false });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  async populateSearchTable(area) {
    axios.get(`${process.env.REACT_APP_SERVER_URL}cardvalue/GetSearchData?area=${area}`)
    .then((response) => response.data)
    .then((data) => {
      if (!this.mounted) {
        this.setState({ searchData: data });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  renderRealDataCards(cardValues) {
    return (
      <div className="flex-data">
        {cardValues !== undefined ? (
          cardValues.map((cardValue, i) => (
            <WidgetCard
              key={i}
              type={cardValue.type}
              value={cardValue.statusLength}
              handler={() => {}}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    );
  }

  renderHistoryDataCards(cardValues) {
    return (
      <div className="flex-data-history">
        {cardValues !== undefined ? (
          cardValues.map((cardValue, i) => (
            <WidgetCard
              key={i}
              type={cardValue.type}
              value={cardValue.currentValue}
              //percentage={
              //  cardValue.currentPercentage === 0 ? '' : cardValue.currentPercentage + ' %'
              //}
              handler={() => {}}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    );
  }

  renderSearchTable() {
    return <OrderSearch orders={this.state.searchData} />;
  }

  renderActionBox() {
    return <ActionBox data={this.state.actionsData} loading={this.state.loadingActionBox} />;
  }

  render() {
    let realDataCards = this.state.loadingRealData ? (
      <p>
        <em>{Titles.LOADING}</em>
      </p>
    ) : (
      this.renderRealDataCards(this.state.cardvaluesRealTime)
    );
    let actionBox = this.renderActionBox();
    let historyDataCards = this.state.loadingHistoryData ? (
      <p>
        <em>{Titles.LOADING}</em>
      </p>
    ) : (
      this.renderHistoryDataCards(this.state.historyDataCards)
    );
    let searchArea = this.renderSearchTable();
    return (
      <>
       
        <div className="widget-wrap-overview">
        
          <div className="data-container">
            <div className="overview-title">
                <h3 className="orderTitle">{Titles.REALTIME_DATE_NAME}</h3>
                <div>
                {realDataCards}
                </div>
          </div>
            
          </div>
          <div className="data-overviewcontainer-actionbox">
            <div className="overview-title">
             <h3 className="orderTitle">{Titles.ACTIONBOX_NAME}</h3>
            </div> 
            {actionBox}
          </div>
        </div>
        <div className="listContainer">
          <div className="orderSearchHeaderContainer">
            <h3 className="orderTitle">{Titles.HISTORY_CARDS_NAME}</h3>
            <AggregatedDataFilterHeader onHeaderClick={this.onHeaderClick} />
          </div>
          {historyDataCards}
        </div>
        {searchArea}
      </>
    );
  }
}

export default Overview;
