import './home.scss';
import React, { Component } from 'react';
import WidgetCard from '../../components/widgetCard/WidgetCard';
import OrderTable from '../../components/orderTable/OrderTable';
import * as Titles from '../../util/Titles';
import ActionBox from '../../components/actionBox/ActionBox';
import axios from "axios";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardvalues: [],
      loading: true,
      initialOrders: [],
      activeType: '',
      storeCode: this.props.storeId,
      loadingActionBox: true,
      areaCode: this.props.area
    };
  }

  componentDidMount() {
    this.populateOrderData(this.state.storeCode);
    this.populateActionBox(this.state.areaCode, this.state.storeCode);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeId !== this.props.storeId) {
      this.setState({ storeCode: this.props.storeId });
      this.setState({ areaCode: this.props.areaId });
      this.populateOrderData(this.props.storeId);
      this.populateActionBox(this.props.area, this.props.storeId);
    }
  }

  async populateOrderData(storeCode) {
    axios.get(`${process.env.REACT_APP_SERVER_URL}cardvalue/GetOrdersByStoreCode?storeCode=${storeCode}`)
    .then((response) => response.data)
    .then((data) => {
      this.setState({
        activeType: Titles.CASE_TOTAL,
        cardvalues: data,
        loading: false,
        initialOrders: data[0]?.orders
      });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  handleWidgetClick(tableOrders, type) {
    this.setState({ activeType: type, initialOrders: tableOrders });
  }
  renderActionBox() {
    return <ActionBox data={this.state.actionsData} loading={this.state.loadingActionBox} />;
  }
  renderWidgetCards(cardValues) {
    return (
      <>
        {cardValues.map((cardValue, i) => (
          <WidgetCard
            key={i}
            active={this.state.activeType === cardValue.type ? true : false}
            type={cardValue.type}
            value={cardValue.statusLength}
            handler={() => this.handleWidgetClick(cardValue.orders, cardValue.type)}
          />
        ))}
      </>
    );
  }

  renderOrderTable() {
    return <OrderTable ordertables={this.state.initialOrders} storeCode={this.state.storeCode} />;
  }
  async populateActionBox(areaCode, storeCode) {
    this.setState({ loadingActionBox: true });
    axios.get(`${process.env.REACT_APP_SERVER_URL}cardvalue/GetActionsForStore?areaCode=${areaCode}&storeCode=${storeCode}`)
    .then((response) => response.data)
    .then((data) => {
      this.setState({ actionsData: data, loadingActionBox: false });
    })
    .catch((error) => {
      console.log(error);
    });
  }
  render() {
    let widgetCards = this.state.loading ? (
      <p>
        <em>{Titles.LOADING}</em>
      </p>
    ) : (
      this.renderWidgetCards(this.state.cardvalues)
    );
    let orderTable =
      this.state.initialOrders == [] ? (
        <p>
          <em>{Titles.LOADING}</em>
        </p>
      ) : (
        this.renderOrderTable()
      );
    let actionBox = this.renderActionBox();
    return (
      <div>
        <div className="widget-wrap">
          <div className="widget">{widgetCards}</div>
          <div className="data-container-actionbox">
            <h3 className="orderTitle">{Titles.ACTIONBOX_NAME}</h3>
            {actionBox}
          </div>
        </div>
        <div className="listContainer">{orderTable}</div>
      </div>
    );
  }
}

export default Home;
