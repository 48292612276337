import { TableCell } from '@mui/material';
import { FormatDateTime } from '../../../util/Util';

export const TableDataRowUncollectedOrder = (props) => {
  return (
    <>
      <TableCell className="tableCell">{props.order.referenceNumber}</TableCell>
      <TableCell className="tableCell">{props.order.siteId}</TableCell>
      <TableCell className="tableCell">{FormatDateTime(props.order.packingFinishDate)}</TableCell>
      <TableCell className="tableCell">{props.order.sumQuantity}</TableCell>
      <TableCell className="tableCell">
        <span className={`status ${props.order.status}`}>{props.order.displayStatus}</span>
      </TableCell>
    </>
  );
};

export default TableDataRowUncollectedOrder;
