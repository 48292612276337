import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import './order-table-header.scss';
import * as React from 'react';
import { Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Component } from 'react';
import * as Titles from '../../util/Titles';

class OrderTableHeaderPanel extends Component {
  constructor(props) {
    super(props);
    this.searchItems = this.props.orderIds;
    this.tableHeader = this.props.tableHeader;
    this.state = { activeHeader: '0' };
  }

  render() {
    return (
      <div className="orderhead">
        <div className="orderTitle">
          <h3>
            {this.props.tableHeader}
            {this.props.tableHeader !== Titles.ORDER_TABLE_HEADER ? (
              <Tooltip title={Titles.ORDER_TABLE_HEADER_TOOLTIP}>
                <ErrorOutlineIcon color="primary" fontSize="xx-small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </h3>
        </div>
        <div className="dayFilltering">
          <ButtonGroup variant="text" aria-label="text button group">
            <Button
              onClick={(e) => {
                this.props.onHeaderClick(e, '1');
                this.state.activeHeader === '1'
                  ? this.setState({ activeHeader: '0' })
                  : this.setState({ activeHeader: '1' });
              }}
              className={this.state.activeHeader === '1' ? Titles.ACTIVE : ''}>
              {Titles.DATE_FILTER_BUTTON_TODAY}
            </Button>
            <Button
              onClick={(e) => {
                this.props.onHeaderClick(e, '2');
                this.state.activeHeader === '2'
                  ? this.setState({ activeHeader: '0' })
                  : this.setState({ activeHeader: '2' });
              }}
              className={this.state.activeHeader === '2' ? Titles.ACTIVE : ''}>
              {Titles.DATE_FILTER_BUTTON_2DAYS}
            </Button>
            <Button
              onClick={(e) => {
                this.props.onHeaderClick(e, '3');
                this.state.activeHeader === '3'
                  ? this.setState({ activeHeader: '0' })
                  : this.setState({ activeHeader: '3' });
              }}
              className={this.state.activeHeader === '3' ? Titles.ACTIVE : ''}>
              {Titles.DATE_FILTER_BUTTON_7DAYS}
            </Button>
          </ButtonGroup>
        </div>
        <div className="autocomplete">
          <Autocomplete
            className=""
            freeSolo
            disableClearable
            options={this.searchItems}
            onChange={(event, value) => {
              this.props.onAutocompleteChange(value);
            }}
            renderInput={(params) => (
              <TextField
                className="textField"
                {...params}
                label="type order number.."
                onChange={this.props.onAutocompleteChange}
                InputProps={{
                  ...params.InputProps,
                  type: 'search'
                }}
              />
            )}
          />
        </div>
      </div>
    );
  }
}

export default OrderTableHeaderPanel;
