import React, { Component } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import './analytics.scss';

export class Analytics extends Component {
  render() {
    return (
      <div className="analytics">
        <Sidebar />
        <div className="analyticsContainer">
          <iframe
            title="BOPIS Spain & US Pilot Report"
            width="1140"
            height="541.25"
            src="https://app.powerbi.com/reportEmbed?reportId=e9284651-c660-4ec6-8543-8079111bd762&autoAuth=true&ctid=30f52344-4663-4c2e-bab3-61bf24ebbed8"
            frameBorder="0"
            allowFullScreen="true"></iframe>
        </div>
      </div>
    );
  }
}

export default Analytics;
