import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, stagingApiScope, productionApiScope } from "./util/AuthConfig";
import axios from "axios";

let loginApiRequest = productionApiScope;

console.log(`current environment: ${process.env.REACT_APP_RUNNING_ENV}`);

if (process.env.REACT_APP_RUNNING_ENV == 'staging') {
  loginApiRequest = stagingApiScope
  msalConfig.auth.clientId = "be41d9c6-bfdc-4e97-ae90-bf812d3dde1d";
}

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const baseUrl = "/";

const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.request.use(
  async (response) => {
    const account = msalInstance.getAllAccounts()[0];

    if (account === undefined)
    {
      console.log("------------------------------------- cannot access user account");
      return;
    }

    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginApiRequest,
      account: account,
    });
    response.headers.Authorization = `Bearer ${ msalResponse.accessToken }`;
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

msalInstance.initialize()
.then(() =>

// Handle the redirect flows
msalInstance
    .handleRedirectPromise()
    .then((tokenResponse) => {
        if (!tokenResponse) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                // No user signed in
                console.log("------------------------------------- about to do login redirect");
                msalInstance.loginRedirect(loginApiRequest);
            }
        } else {
          window.location.reload();
        }
    })
    .catch((err) => {
        console.error(err);
    })
);

const rootElement = createRoot(document.getElementById('root'));
rootElement.render(
  <BrowserRouter basename={baseUrl}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
