import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import React, { Component } from 'react';
import TableRow from '@mui/material/TableRow';

class ExpandableContentRow extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={this.props.open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {this.props.content}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }
}

export default ExpandableContentRow;
