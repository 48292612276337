import './header.scss';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import * as Titles from '../../util/Titles';
import axios from "axios";

export const Header = (props) => {
  let isAggregatedView = false;
  let disabled = true;
  const location = useLocation();
  const [store, setStore] = React.useState(props.storeId);
  const [area, setArea] = React.useState(props.area);
  const [stores, setStores] = React.useState(props.stores);
  const [areas, setAreas] = React.useState([]);

  if(areas.length == 0)
  {
    getAreas();
  }  
  
   async function getAreas()
  {
    axios.get(`${process.env.REACT_APP_SERVER_URL}service/GetAreas`)
    .then((response) => response.data)
    .then((data) => {
      setAreas(data); 
    })
    .catch((error) => {
      console.log(error);
    });
  } 
  
    async function getStores(value) {
      axios.get(`${process.env.REACT_APP_SERVER_URL}service/GetStoresByArea?area=${value}`)
      .then((response) => response.data)
      .then((data) => {
        setStores(data);
        if (data[0] != undefined) {
          setStore(data[0]);
          props.onStoreChange(data[0]);
        } else {
          setStore(Titles.ALLSTORES);
        }
        })
      .catch((error) => {
        console.log(error);
      });
    }

  if (location.pathname === '/') {
    isAggregatedView = true;
  }

  useEffect(() => {
    if (!isAggregatedView) {
      props.onStoreChange(store);
      props.onAreaChange(area);
      if (area == Titles.ALLAREAS) {
        getStores('Area810');
        setArea('Area810');
        props.onAreaChange(area);
      } else if (store == Titles.ALLSTORES) {
        getStores(area);
        setArea(area);
      }
    } else {
      props.onAreaChange(area);
      props.onStoreChange(Titles.ALLSTORES);
    }
  }, [isAggregatedView]);

  const handleStoreChange = (event) => {
    setStore(event.target.value);
    props.onStoreChange(event.target.value);
  };

  const handleAreaChange = (event) => {
    getStores(event.target.value);
    setArea(event.target.value);
    props.onAreaChange(event.target.value);
  };

  return (
    
    <div className="header">
     
      <div className="wrapper">
        <div className="headerTitle">
          <h1>{Titles.HEADER_NAME}</h1>
        </div>
        <div className="storeName">
          {isAggregatedView ? (disabled = true) : (disabled = false)}
         
          <select value={area} onChange={handleAreaChange}>
                  <option value="AllAreas" disabled={!disabled}>
                     {Titles.DROPDOWN_ALLAREAS}
                  </option>
                  {areas.map((i) => (
                    <option value={i.areaCode} key={i.areaCode}>
                      {i.storeArea}
                    </option>
                  ))}
                  {/* {areas.map((area) => <option key={area.value} value={area.value}>{area.display}</option>)}  */}
                  {/* <option value="AllAreas" disabled={!disabled}>
                     {Titles.DROPDOWN_ALLAREAS}
                  </option>
                  {fetchAreas.map((area) => <option key={area.value} value={area.value}>{area.display}</option>)} */}
          </select>
          {stores == undefined ? (
            <></>
          ) : (
            <select
              value={isAggregatedView ? Titles.ALLSTORES : store}
              onChange={handleStoreChange}>
              <option value="AllStores" disabled={!disabled}>
                {Titles.DROPDOWN_ALLSTORES}
              </option>
              {stores.map((i) => (
                <option value={i} disabled={disabled} key={i}>
                  {i}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
