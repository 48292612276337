import './product-description.scss';
import React, { Component } from 'react';
import * as Titles from '../../util/Titles';

class ProductDescription extends Component {
  render() {
    return (
      <div className="productinfo">
        <div className="productimg">
          {' '}
          <img src={this.props.productDescription.imageUri} width="60px" height="90px" alt="" />
        </div>
        <div className="productdetail">
          <p>
            {' '}
            {Titles.PRODUCT_DESCRIPTION_ARTNR} {this.props.productDescription.sku}{' '}
          </p>
          <p>
            {' '}
            {Titles.PRODUCT_DESCRIPTION_DESCRIPTION} {this.props.productDescription.description}
          </p>
          <p>
            {' '}
            {Titles.PRODUCT_DESCRIPTION_COLOR} {this.props.productDescription.color}
          </p>
          <p>
            {' '}
            {Titles.PRODUCT_DESCRIPTION_SIZE} {this.props.productDescription.size}
          </p>
          <p>
            {' '}
            {Titles.PRODUCT_DESCRIPTION_QUANTITY} {this.props.productDescription.quantity?.quantity}
          </p>
        </div>
        {this.props.productDescription.picked !== '' ? (
          <div className="shortPick">{this.props.productDescription.picked}</div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ProductDescription;
