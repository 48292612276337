import { TableCell, TableRow } from '@mui/material';
import * as Titles from '../../../util/Titles';

export const HistoryDataHeader = () => {
  return (
    <TableRow>
      <TableCell className="tableCella"></TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_ORDER_NUMBER}</TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_FINISH_DATE}</TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_ORDER_QUANTITY} </TableCell>
      <TableCell className="tableCella">{Titles.CELL_NAME_STATUS}</TableCell>
    </TableRow>
  );
};
