import { FormatDateTime } from '../../../util/Util';
import TableCell from '@mui/material/TableCell';
import * as Titles from '../../../util/Titles';

export const OrderTableRowHistoryData = (props) => {
  return (
    <>
      <TableCell className="tableCell">{props.order.referenceNumber}</TableCell>
      <TableCell className="tableCell">{FormatDateTime(props.order.finishDate)}</TableCell>
      <TableCell className="tableCell">{props.order.sumQuantity}</TableCell>
      <TableCell className="tableCell">
        {props.order.isShortPick & (props.order.status != Titles.ORDER_STATUS_SHORT_PICK) ? (
          <span className={`status ShortPick`}>Short pick</span>
        ) : (
          <></>
        )}
        &nbsp;
        <span className={`status ${props.order.status}`}>{props.order.displayStatus}</span>
      </TableCell>
    </>
  );
};

export default OrderTableRowHistoryData;
