import React, { Component } from 'react';
import './sidebar.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryIcon from '@mui/icons-material/History';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import { Link } from 'react-router-dom';
import Logo from '../../Assets/hm-logo.png';
import * as Titles from '../../util/Titles';

export class Sidebar extends Component {
  render() {
    return (
      <div className="sidebar">
        <div className="top">
          <Link to="/" style={{ textDecoration: 'none' }}>
            <span className="logo">
              <img src={Logo} alt="" />
            </span>
          </Link>
        </div>
        <hr />
        <div className="center">
          <ul>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <li>
                <MultilineChartIcon className="icon" />
                <span>{Titles.SIDEBAR_MENU_OVERVIEW}</span>
              </li>
            </Link>
            <Link to="dashboard" style={{ textDecoration: 'none' }}>
              <li>
                <DashboardIcon className="icon" />
                <span>{Titles.SIDEBAR_MENU_DASHBOARD}</span>
              </li>
            </Link>
            {/* <Link to="analytics" style={{ textDecoration: "none" }}>
                            <li>
                                <LeaderboardIcon className="icon" />
                                <span>Analytics</span>
                            </li>
                        </Link> */}

            <Link to="history" style={{ textDecoration: 'none' }}>
              <li>
                <HistoryIcon className="icon" />
                <span>{Titles.SIDEBAR_MENU_HISTORY}</span>
              </li>
            </Link>
          </ul>
        </div>
        <div className="bottom"></div>
      </div>
    );
  }
}

export default Sidebar;
