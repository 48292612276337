import { TableCell } from '@mui/material';
import '../action-box.scss';

export const TableDataRowPendingOrder = (props) => {

  return (
    <>
      <TableCell className="tableCell">{props.order.referenceNumber}</TableCell>
      <TableCell className="tableCell">{props.order.siteId}</TableCell>
      <TableCell className="tableCell">{props.order.sumQuantity}</TableCell>
      <TableCell className="tableCell">
        <span className={`status Pending`}>Pending Action</span>
      </TableCell>
    </>
  );
};

export default TableDataRowPendingOrder;
