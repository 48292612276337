import React, { Component } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Tooltip } from '@mui/material';
import './aggregated-data-filter-header.scss';
import * as Titles from '../../util/Titles';

export class AggregatedDataFilterHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { activeHeader: Titles.MONTH };
  }

  render() {
    return (
      <div className="aggregatedDataFiltering">
        <ButtonGroup variant="text" aria-label="text button group">
          <Tooltip title={Titles.TOOLTIP_TEXT_YESTERDAY}>
            <Button
              onClick={(e) => {
                this.props.onHeaderClick(e, Titles.YESTERDAY);
                this.state.activeHeader === Titles.YESTERDAY
                  ? this.setState({ activeHeader: Titles.MONTH })
                  : this.setState({ activeHeader: Titles.YESTERDAY });
              }}
              className={this.state.activeHeader === Titles.YESTERDAY ? Titles.ACTIVE : ''}>
              Yesterday
            </Button>
          </Tooltip>
          <Tooltip title={Titles.TOOLTIP_TEXT_WEEK}>
            <Button
              onClick={(e) => {
                this.props.onHeaderClick(e, Titles.WEEK);
                this.state.activeHeader === Titles.WEEK
                  ? this.setState({ activeHeader: Titles.MONTH })
                  : this.setState({ activeHeader: Titles.WEEK });
              }}
              className={this.state.activeHeader === Titles.WEEK ? Titles.ACTIVE : ''}>
              Last Week
            </Button>
          </Tooltip>
          <Tooltip title={Titles.TOOLTIP_TEXT_TWOWEEKS}>
            <Button
              onClick={(e) => {
                this.props.onHeaderClick(e, Titles.TWO_WEEKS);
                this.state.activeHeader === Titles.TWO_WEEKS
                  ? this.setState({ activeHeader: Titles.MONTH })
                  : this.setState({ activeHeader: Titles.TWO_WEEKS });
              }}
              className={this.state.activeHeader === Titles.TWO_WEEKS ? Titles.ACTIVE : ''}>
              Last 2 weeks
            </Button>
          </Tooltip>
          <Tooltip title={Titles.TOOLTIP_TEXT_MONTH}>
            <Button
              onClick={(e) => {
                this.props.onHeaderClick(e, Titles.MONTH);
                this.state.activeHeader === Titles.MONTH
                  ? this.setState({ activeHeader: Titles.MONTH })
                  : this.setState({ activeHeader: Titles.MONTH });
              }}
              className={this.state.activeHeader === Titles.MONTH ? Titles.ACTIVE : ''}>
              Month
            </Button>
          </Tooltip>
        </ButtonGroup>
      </div>
    );
  }
}

export default AggregatedDataFilterHeader;
