import React, { Component } from 'react';
import TableRow from '@mui/material/TableRow';
import ProductDescription from '../productDescription/ProductDescription';
import ExpandableContentRow from './ExpandableContentRow';
import RowExpandingArrowCell from './RowExpandingArrowCell';
import * as Titles from '../../util/Titles';
import axios from "axios";

class OrderRow extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, open: false, productDescription: {} };
  }

  async fetchProductDetails(order) {
    this.setState({ loading: true });
    axios.get(`${process.env.REACT_APP_SERVER_URL}cardvalue/GetOrderRowDetails?orderId=${order.id}&siteId=${order.siteId}&worklistId=${order.relatedWorklistItemId}`)
    .then((response) => response.data)
    .then((data) => {
      this.setOrderDetailQuantities(data);
      this.setState({ productDetails: data, loading: false });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  getSkus(row) {
    var skus = row.orderQuantities
      .map((value) => {
        return 'skuNumbers=' + value.sku;
      })
      .join('&');
    return skus;
  }

  setOrderDetailQuantities = (orderDetails) => {
    orderDetails.forEach((element) => {
      element.quantity = this.props.order.orderQuantities.filter((el) => {
        return el.sku === element.sku;
      })[0];
    });
  };

  onRowExpand(e) {
    this.setState({ open: e });
    if (e) {
      this.fetchProductDetails(this.props.order);
    }
  }

  renderExpandableContent() {
    let productDetails = this.state.loading ? (
      <p>
        <em>{Titles.LOADING}</em>
      </p>
    ) : (
      this.state.productDetails.map((element) => (
        <ProductDescription key={element.sku} productDescription={element} />
      ))
    );
    return <ExpandableContentRow open={this.state.open} content={productDetails} />;
  }

  render() {
    let expandableContent = this.renderExpandableContent();
    return (
      <React.Fragment>
        <TableRow>
          <RowExpandingArrowCell
            open={this.state.open}
            onRowExpand={(paramFromChild) => this.onRowExpand(paramFromChild)}
          />
          {this.props.dataRow}
        </TableRow>
        {expandableContent}
      </React.Fragment>
    );
  }
}

export default OrderRow;
