import Home from './pages/home/Home';
import './pages/home/home.scss';
import Analytics from './pages/analytics/Analytics';
import History from './pages/history/History';
import Overview from './pages/overview/Overview';
import Sidebar from './components/sidebar/Sidebar';
import React, { Component } from 'react';
import Header from './components/header/Header';
import { AddDataToCache } from './util/Cache';
import { Routes, Route } from 'react-router-dom';
import * as Titles from './util/Titles';
import axios from "axios";

export default class App extends Component {
  static displayName = App.name;
  static cachesToFetch = [
    { cacheName: Titles.STOREDATA_CACHE_NAME, id: Titles.STOREDATA_CACHE_STOREID },
    { cacheName: Titles.STOREDATA_CACHE_NAME, id: Titles.STOREDATA_CACHE_AREAID }
  ];

  constructor(props) {
    super(props);    
    this.state = { storeId: null, areaId: null, stores: [], areasLoad:false, cachedData: [], };
    this.getMultipleCacheData(App.cachesToFetch);
  }

  async getMultipleCacheData(cacheNames) {
    if (typeof caches === 'undefined') return false;

    var dict = [];

    var cacheDataArray = [];
    for (var i = 0; i < cacheNames.length; i++) {
      const cacheStorage = await caches.open(cacheNames[i].cacheName);
      const cachedResponse = await cacheStorage.match(cacheNames[i].id);
      // If no cache exists
      if (!cachedResponse || !cachedResponse.ok) {
        cacheDataArray[i] =
          cacheNames[i].id == Titles.STOREDATA_CACHE_STOREID ? Titles.ALLSTORES : Titles.ALLAREAS;
      } else {
        var data = await cachedResponse.json();
        cacheDataArray[i] = data;
        //if (data != 'AllStores' && data != 'AllAreas') {
        // this.state[cacheNames[i].id] = data;
        //}
      }
      //this.state[cacheNames[i].id] = cacheDataArray[i];
      
      // console.log("key (before) " + cacheNames[i].id);
      // console.log("value (before) " + cacheDataArray[i]);

      // if (cacheNames[i].id == "areaId")
      // {
      //   cacheDataArray[i] = "AllAreas";
      // }

      console.log("key (after) " + cacheNames[i].id);
      console.log("value (after) " + cacheDataArray[i]);

      this.setState({ [cacheNames[i].id]: cacheDataArray[i] });

      dict.push({
        key:   cacheNames[i].id,
        value: cacheDataArray[i]
      });

      dict[cacheNames[i].id] = cacheDataArray[i];
    }

console.log("test " + dict['areaId']);

    axios.get(`${process.env.REACT_APP_SERVER_URL}service/GetStoresByArea?area=${dict['areaId']}`)
    .then((response) => response.data)
    .then((data) => {
      this.setState({ stores: data });
      this.setState({ cachedData: cacheDataArray });
      this.setState({ areasLoad: true });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <div className="App">
        <div className="home">
          <div><Sidebar /></div>
          
          <div className="homeContainer">
            {this.state.cachedData[0] != undefined &&
            this.state.cachedData[1] &&
            this.state.stores != undefined ? (
              <Header
                storeId={this.state.storeId}
                area={this.state.areaId}
                stores={this.state.stores}
                areasLoad = {this.state.areasLoad}
                onStoreChange={(value) => {
                  this.setState({ storeId: value });
                  this.setState({ areaId: this.state.areaId });
                  AddDataToCache(
                    Titles.STOREDATA_CACHE_NAME,
                    Titles.STOREDATA_CACHE_STOREID,
                    value
                  );
                }}
                onAreaChange={(value) => {
                  this.setState({ areaId: value });
                  AddDataToCache(Titles.STOREDATA_CACHE_NAME, Titles.STOREDATA_CACHE_AREAID, value);
                }}
              />
            ) : (
              <></>
            )}
            {this.state.areaId != null ? (
              <Routes path="/">
                <Route index element={<Overview area={this.state.areaId} />} />
                <Route path="dashboard" element={<Home storeId={this.state.storeId}area={this.state.areaId} />} />
                <Route path="analytics" element={<Analytics />} />
                <Route path="history" element={<History storeId={this.state.storeId} />} />
              </Routes>
            ) : (
              <p>Something went wrong. Try to choose another area</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
